import { FC, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Label } from "@/components/_ui/label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/_ui/dialog";
import { Checkbox } from "@/components/_ui/checkbox";
import LoadingDots from "@/components/loading-dots";

import { apolloClient } from "@/services/apollo.service/apollo.service";
import { removeTeamMutationDocument, teamDeletionCodeQueryDocument } from "@/graphql/common";

import { useTeamById } from "@/hooks/api/common";
import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";

export interface TeamDeleteButtonProps {
  team: {
    id: string;
  };
  className?: string;
}

export const TeamDeleteButton: FC<TeamDeleteButtonProps> = ({ className, ...props }) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { team } = useTeamById({ id: props.team.id });

  const [dialogisOpen, setDialogisOpen] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [checkBoxIsChecked, setCheckBoxIsChecked] = useState(false);
  const [error, setError] = useState<string | null>(null);

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  function confirmationCodeValueChange(e: React.FormEvent<HTMLInputElement>) {
    const newValue = e.currentTarget.value;
    setConfirmationCode(newValue);
  }

  function toggleCheckBox() {
    setCheckBoxIsChecked(!checkBoxIsChecked);
  }

  async function requestTeamDeletionCode() {
    try {
      const { data } = await apolloClient.query({
        query: teamDeletionCodeQueryDocument,
        fetchPolicy: "no-cache",
        variables: {
          input: {
            id: props.team.id,
          },
        },
      });
      setEmail(data.requestTeamDeletionCode.email);
    } catch (e) {
      setError(
        "Es konnte kein Bestätigungs Code versandt werden. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal oder kontaktieren Sie den unseren Support.",
      );
    }
  }

  async function handleDeleteButtonPressed() {
    requestTeamDeletionCode();
    setDialogisOpen(true);
  }

  async function handleConfirmationButtonPressed() {
    try {
      const { data } = await apolloClient.mutate({
        mutation: removeTeamMutationDocument,
        fetchPolicy: "no-cache",
        variables: {
          input: confirmationCode,
        },
      });

      if (data) {
        window.location.replace(new URL(`${window.location.origin}/${currentWorkspace.workspaceUrl}/settings`));
      }
    } catch (e) {
      setError(
        "Die Löschung des Teams ist fehlgeschlagen. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal oder kontaktieren Sie den unseren Support.",
      );
    }
  }

  if (!team) {
    return <></>;
  }

  return (
    <div className={className}>
      <Button variant="destructive" onClick={handleDeleteButtonPressed}>
        Team löschen
      </Button>

      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange}>
        <DialogContent className="p-10 sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>
              <span className="text-base font-normal">Bestätigen Sie die Löschung des Teams</span>
            </DialogTitle>
            <DialogDescription asChild>
              <div className="h-4 w-full border-b border-solid" />
            </DialogDescription>
          </DialogHeader>
          <div className="relative max-w-full font-light">
            <p className="mb-4 text-sm text-muted-foreground">
              Wenn Sie sicher sind, dass die das Team <span className="font-medium">{team.name}</span> löschen wollen,
              lesen Sie bitte unten weiter.
            </p>
            <p className="mb-4 text-sm text-muted-foreground">
              Bitte beachten Sie, dass diese Aktion unwiderruflich ist und in einer kompletten Löschung aller mit dem
              Team assoziierten Daten verbunden ist.
            </p>
            <div className="mb-4 text-sm font-medium text-muted-foreground">
              {email && (
                <div>
                  Geben Sie den Bestätigungs-Code ein, den wir an <code className="font-base">{email}</code> gesendet
                  haben.
                </div>
              )}
              {!email && (
                <div>
                  <br />
                  <LoadingDots />
                  <br />
                </div>
              )}
            </div>
            <div>
              <Label htmlFor="confirmation-code" className="mb-10">
                Bestätigungs Code
              </Label>
              <Input
                id="confirmation-code"
                type="text"
                placeholder="Geben Sie den Bestätigungs Code ein"
                onChange={confirmationCodeValueChange}
              />
            </div>
            <div className="mt-6 flex items-start justify-start space-x-2">
              <Checkbox
                id="terms"
                onClick={() => {
                  toggleCheckBox();
                }}
              />
              <label
                htmlFor="terms"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Ich verstehe und bestätige, dass die gesamten Daten des Teams unwiderruflich gelöscht werden und möchte
                fortfahren.
              </label>
            </div>
            {error && (
              <div className="mt-6 flex items-start justify-start text-sm font-normal text-destructive">{error}</div>
            )}
          </div>
          <DialogFooter className="flex flex-row flex-wrap">
            <div className="h-4 w-full border-b border-solid" />
            <div className="flex w-full flex-row justify-center pt-4">
              <Button
                type="button"
                variant="destructive"
                className="flex h-10 w-full items-center justify-center rounded-md border text-sm transition-all hover:bg-destructive/90 focus:outline-none"
                onClick={handleConfirmationButtonPressed}
                disabled={confirmationCode.length == 0 || !checkBoxIsChecked}
              >
                Team Löschen
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
